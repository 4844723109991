import { AxiosResponse } from "axios";
import service from "../../../util/axiosConfig";
import { INTIALISE } from "../../../util/config";
import { JavaResponse } from "../../../types";
import { Dispatch } from "react";

type UserPermissions = {
    [key: string]: boolean;
};

type User = {
    personName: string;
    userType: string;
    email: string;
    phone: string;
    multipleLeads: boolean;
    uuid: string;
    userId: string;
    phoneVerified: boolean;
    isEmployee: boolean;
    dob: string;
    gender: string;
    agentUuid: string;
    isMigrated: boolean;
    permissions: UserPermissions;
};

type InitiateResponse = {
    d_verify_phone: boolean;
    userTypeChange: boolean;
    page: string;
    user: User;
};

export const userService = {
    /**
     * @description This function is used to login the user via a url which contains token
     * @param {string} url - The url which contains the token and if it also contains session boolean then it will use session to login instead of using local storage
     * @param {Dispatch<any>} dispatch - The dispatch function to dispatch the actions
     */
    urlLogin: {
        session: (url: string, dispatch: Dispatch<any>): Promise<string> => {
            return new Promise(async (resolve, reject) => {
                try {
                    const urlParams = new URLSearchParams(url);
                    const page = urlParams.get("page") || "";
                    const token = urlParams.get("token") || "";
                    const topBar = urlParams.get("topBar") || "true";
                    const navBar = urlParams.get("navBar") || "true";
                    const session = urlParams.get("sessionStorage") || "false";

                    let initiateResponse = await userService.initiate(token);
                    if(session === "true") {
                        sessionStorage.setItem("Authorization", `Bearer ${token}`);
                    }
                    else {
                        localStorage.setItem("Authorization", `Bearer ${token}`);
                    }
                    localStorage.setItem("navBar", navBar);
                    localStorage.setItem("topBar", topBar);
                    dispatch({
                        type: "set-uuid",
                        uuid: initiateResponse.user.uuid,
                    });
                    dispatch({
                        type: "set-user",
                        payload: initiateResponse.user,
                    });
                    resolve(page);
                } catch (error) {
                    reject(error);
                }
            });
        },
    },

    /**
     * @description This function is used to login the user via a token
     * @param {string} token The login token
     * @returns - The InitiateResponse object
     */
    initiate: (token: string): Promise<InitiateResponse> => {
        return new Promise((resolve, reject) => {
            service
                .get(INTIALISE, {
                    Authorization: "Bearer " + token
                })
                .then(
                    (
                        response: AxiosResponse<JavaResponse<InitiateResponse>>
                    ) => {
                        resolve(response.data.response);
                    }
                )
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

