import { Outlet, useNavigate } from "react-router-dom";
import NewTopbar from "../components/Topbar/NewTopbar";
import EmployeeNavbar from "../components/Topbar/Employee/Navbar";
import { useSelector } from "react-redux";
import { useStateValue } from "../util/stateProvider";
import { RootState } from "../store";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer/footer";

const UserIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/user.png";

const WrapperRoute = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [{ user, leadInfo }] = useStateValue();
    const homeBannerBackground = useSelector(
        (state: RootState) => state.home.homeBannerBackground
    );
    const { homeDtoV2 } = useSelector((state: RootState) => state.home);

    const pathname = window.location.pathname;

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            if (scrollContainerRef.current.scrollTop > 88) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        }
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, [user]);

    if (
        JSON.parse(sessionStorage.getItem("user") || "{}")?.userType ===
        "EMPLOYEE"
    ) {
        return (
            <>
                <div
                    style={{
                        backgroundColor:
                            JSON.parse(sessionStorage.getItem("user") || "{}")
                                ?.userType === "EMPLOYEE"
                                ? "white"
                                : "",
                        display: "flex",
                    }}
                >
                    {!homeDtoV2?.d_enrollment &&
                        pathname !== "/enrollmentHome" &&
                        pathname !== "/newEnrollment" &&
                        localStorage?.getItem("navBar") !== "false" && (
                            <EmployeeNavbar scrolled={isScrolled} />
                        )}
                    <div
                        ref={scrollContainerRef}
                        className={`body-container_v2 ${
                            homeDtoV2?.d_enrollment ||
                            localStorage?.getItem("navBar") === "false"
                                ? "enrollmentEnabled"
                                : ""
                        }`}
                        style={{
                            height:
                                JSON.parse(
                                    sessionStorage.getItem("user") || "{}"
                                )?.userType === "EMPLOYEE"
                                    ? "100vh"
                                    : "",
                        }}
                    >
                        {(pathname === "/home" ||
                            pathname === "/enrollmentHome") &&
                        localStorage?.getItem("topBar") !== "false" ? (
                            <Box
                                sx={{
                                    width: {
                                        xs: "calc(100% + 40px)",
                                        sm: "100%",
                                    },
                                    height: {
                                        xs: "88px",
                                        sm: "64px",
                                    },
                                    margin: {
                                        xs: "0 -20px",
                                        sm: "0 auto",
                                    },
                                    paddingBlock: {
                                        xs: "24px",
                                        sm: "12px",
                                    },
                                    background: {
                                        xs: homeBannerBackground,
                                        sm: "white",
                                    },
                                    borderBottom: `1px solid var(--Components-Outline, #ECEFFF)`,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        maxWidth: { xs: "100%", md: "770px" },
                                        padding: { xs: "0 15px", sm: "0" },
                                        margin: "0 auto",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box
                                            width={40}
                                            height={40}
                                            px={1}
                                            py="7px"
                                            sx={{
                                                borderRadius: "20px",
                                                border: "1px solid #FFF",
                                                background:
                                                    "rgba(255, 255, 255, 0.70)",
                                                boxShadow:
                                                    "0px 2px 6px 0px rgba(39, 57, 147, 0.06)",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                navigate("/profile");
                                            }}
                                        >
                                            <img
                                                src={UserIcon}
                                                alt={"Profile"}
                                                style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                }}
                                            />
                                        </Box>

                                        <Typography
                                            variant="h5"
                                            mb={0}
                                            ml={1}
                                            color={(theme) =>
                                                theme.palette.text.primary
                                            }
                                            sx={{
                                                marginRight: 1,
                                            }}
                                        >
                                            {!isMobile && `Hey, `}
                                            {user?.personName.split(" ")[0] ||
                                                ""}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                        }}
                                        width={isMobile ? 140 : 160}
                                        height={40}
                                    >
                                        {leadInfo?.agentLogo && (
                                            <img
                                                src={leadInfo?.agentLogo}
                                                alt="Broker logo"
                                                style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}
                        <Outlet />
                        <Footer />
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <NewTopbar />
            <div className="wrapper">
                <div className="content-wrapper">
                    <div className="body-container">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WrapperRoute;
